<template>


    <div class="d-flex flex-column" v-if="model">

      <div class="my-container d-flex flex-column mb-1">

        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <label class="my-0 mr-1">Documento:</label>
            <span>{{model.orig_file || model.file}}</span>
          </div>
          <a href="#" @click.prevent="downloadFile">descargar</a>
        </div>
        <div class="d-flex">
          <label class="my-0 mr-1"><u><b>Fecha subida:</b></u></label>
          <span>{{toDateStr(model.created_at)}}</span>
        </div>

      </div>

      <template v-if="invoices.length">

        <div class="d-flex">

          <div class="detail-container d-flex flex-column">
            <div class="d-flex">
              <h5 class="mr-1">Facturas</h5>
              <div>
                <b-badge style="margin-top: 7px" variant="danger"> {{invoices.length}}</b-badge>
              </div>

            </div>

            <invoice-detail class="mb-2"
                            v-if="selectedInvoice"
                            :model="selectedInvoice"
                            :pages="model.pages"
                            :id="model.id"
            ></invoice-detail>

            <div class="d-flex justify-content-center">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="invoices.length"
                  :per-page="1"
                  size="sm">

              </b-pagination>
            </div>

            <template v-if="xAllFields && xAllFields.length">

              <h5 style="">Logs de desarrollo:</h5>
              <div class="my-2 my-container d-flex flex-wrap">

                <ocr-field :key="`field_ocr_log_${k}`" v-for="(val,k) in xAllFields"
                           :color="val.color"
                           :k="val.k"
                           :v="val.v">

                </ocr-field>



              </div>

            </template>

          </div>

          <div class="my-container image-container">
            <img width="100%" :src="resolveImageUrl()" alt="reconocimiento optico de la factura">
          </div>


        </div>



      </template>

      <template v-if="xIsProcessing">
        <b-alert show variant="info">
          <b-icon class="mr-1" icon="gear" animation="spin" font-scale="2"></b-icon>
          <span class="pt-1" style="font-size: 13px; font-weight: bold">procesando...</span><br>
          Factura añadida a la cola de extracción. <br>
          Los resultados estarán disponibles en breves momentos
        </b-alert>
      </template>
      <template v-else>

        <b-alert class="mb-1" v-if="!invoices.length" show variant="info">
          No se ha encontrado ninguna factura
        </b-alert>

        <b-alert  class="mb-1" v-if="error" show variant="danger">
          Ha habido un error al extraer las facturas. <br> Estamos trabajando en ello
        </b-alert>

      </template>



    </div>







</template>

<script>

import InvoiceDetail from './invoice-detail'
import ApiService from "@/services/api.service";
import { toDateStr} from "@/helpers";
import OcrField from './ocr-field'

import _ from 'lodash'

export default {
  name: "invoice-view",
  props: ['model'],
  components: {
    InvoiceDetail,
    OcrField
  },

  data() {
    return {
      invoices:[],
      error: null,
      selectedInvoice: null,
      currentPage: 1
    };
  },

  watch: {
    model(val){
      if(val) {
        this.invoices = val.invoices || []
        this.error = val.error
        this.selectedInvoice = this.invoices.length ? val.invoices[0] : null
      }
    },
    currentPage(val){
      if(!this.model) return
      const idx = val - 1
      this.selectedInvoice = this.model.invoices[idx]
    }

  },


  computed: {

    xAllFields() {

      const fields = _.get(this.selectedInvoice, 'ocr.fields',{})
      let ret = []
      for (const [k, v] of Object.entries(fields)) {
        ret.push({ k, v: v.value, color: v.color })
      }
      return ret
    },

    xIsProcessing() {
      return this.model && ['PENDING','PROCESSING'].includes(this.model.status)
    }
  },


  methods: {
    toDateStr,
    downloadFile() {
      const { _id, orig_file } = this.model
      ApiService.download(`api/invoice/${_id}/download`,orig_file)
    },

    resolveImageUrl() {
      const baseUrl = `api/invoice/${this.model._id}/img/${this.selectedInvoice.image}`
      const token = encodeURIComponent(ApiService.getToken())
      return `${ApiService.API_URL}/${baseUrl}?token=${token}`


    },

  },


  async mounted() {}
};
</script>

<style>

.detail-container{
  width: 380px;
}

.image-container {
  width: 100%;
}

</style>
