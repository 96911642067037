var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex ocr-main-container"},[_c('div',{staticClass:"ocr-left-container",style:({
    'border': `2px solid ${_vm.color}`,
  })},[_vm._v(" "+_vm._s(_vm.ellipsis(_vm.k,20,false))+" ")]),_c('div',{staticClass:"ocr-right-container",style:({
    'border-right': `2px solid ${_vm.color}`,
    'border-top': `2px solid ${_vm.color}`,
    'border-bottom': `2px solid ${_vm.color}`,
  })},[_vm._v(" "+_vm._s(_vm.ellipsis(_vm.v,30,false))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }