<template>

  <div class="d-flex justify-content-center">

    <div class="d-flex main-container">

      <div class="d-flex justify-content-center left-container">
        <fieldset  class="p-1 m-0 mr-2 left-container">

          <legend><b-badge class="my-badge">Archivos</b-badge></legend>

          <div class="mb-2 my-tooltip d-flex flex-column">
            <span>Busca facturas anteriores en el histórico</span>
          </div>

          <fieldset  class="my-1">
            <legend class="mb-0"><b-badge>Añadir factura</b-badge></legend>

            <div class="d-flex justify-content-between p-1">
              <input
                  style="font-size: 12px"
                  type='file'
                  name='file'
                  ref="file"
                  @change="onFile"
                  accept=".pdf"
              />
              <!--          accept=".doc,.docx,.pdf, .xls, .xlsx"-->


              <b-button @click="uploadClick" size="sm" v-if="file" variant="outline-dark">
                <b-icon icon="cloud-upload"></b-icon>
                subir
              </b-button>

            </div>


          </fieldset>





          <b-table class="mb-2" id="invoice-table"
                   tdClass=""
                   bordered
                   sort-by="created_at"
                   :sort-desc="true"
                   :current-page="currentPage"
                   :per-page="perPage"
                   style="font-size: 12px"
                   selectable select-mode="single"
                   ref="invoiceTable"
                   @row-selected="onRowSelected"
                   :fields="invoice_fields"
                   :items="invoices">




            <template #cell(created_at)="data">

              <b>{{toDateStr(data.item.created_at)}}</b>

            </template>


            <template #cell(file)="data">

              {{ellipsis((data.item.orig_file || data.item.file),40)}}

            </template>

            <template #cell(status)="data">
              <b-badge class="full-width" variant="success" v-if="['COMPLETED', 'CONFIRMED'].includes(data.item.status)">completado</b-badge>
              <b-badge class="full-width" variant="warning" v-if="data.item.status === 'PENDING'">en cola</b-badge>
              <b-badge class="full-width" variant="info" v-if="data.item.status === 'PROCESSING'">procesando</b-badge>
              <b-badge class="full-width" variant="danger" v-if="data.item.status === 'ERROR'">error</b-badge>
            </template>


          </b-table>

          <div class="d-flex justify-content-center">

            <b-pagination
                v-model="currentPage"
                :total-rows="invoices.length"
                :per-page="perPage"
                size="sm">

            </b-pagination>

          </div>

          <b-alert v-if="!invoices.length" show style="text-align: center" variant="info">
            No has importado ninguna factura aún
          </b-alert>





        </fieldset>

      </div>
      <div class="d-flex justify-content-center right-container">

        <fieldset  class="p-1 m-0 mr-2 right-container">

          <legend><b-badge class="my-badge">Factura</b-badge></legend>


          <invoice-view :model="invoiceSelected"></invoice-view>



        </fieldset>

      </div>




    </div>




  </div>
</template>

<script>


import ApiService from "@/services/api.service";
import InvoiceView from "./invoice-view";
import _ from "lodash"
import {logInfo, logWarning, toDateStr, listToDict, ellipsis} from "@/helpers";

export default {
  name: "invoices",

  components: {
    InvoiceView
  },

  data() {
    return {
      liveWire: null, // interval object used for periodic state fetch (to avoid using websocket)
      invoices: [],
      pending: [],
      file: null,
      currentPage: 1,
      perPage: 25,
      invoiceSelected: null,
      invoice_fields: [
        {
          key: 'created_at',
          label: 'Fecha',
          sortable: true
        },
        {
          key: 'file',
          label: 'Archivo',
          sortable: true
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true
        },


      ],
    };
  },

  methods: {
    ellipsis,

    toDateStr,

    checkIsProcessing(status) {
      return ['PENDING','PROCESSING'].includes(status)
    },

    checkIsCompleted(status) {
      return ['COMPLETED','ERROR'].includes(status)
    },


    //avoid using websocket as client firewall might block ports
    async pullLiveWire() {
      if(this.pending.length) {
        const qs_ids = this.pending.map(el=>el._id).join(",")
        const { data } = await ApiService.get(`/api/invoice/livewire?ids=${qs_ids}`)
        const _new = []
        const _dict = listToDict(data, 'id')

        let changed = false
        for(const _invoice of this.invoices) {
          if(_invoice.id in _dict) {
            if(_dict[_invoice.id].status !== _invoice.status) {
              changed = true
              _new.push(_dict[_invoice.id])
            }
          }else{
            _new.push(_invoice)
          }
        }
        if(changed) {
          this.invoices = _new
          this.currentPage = 1
          this.selectRow(0)
        }
        const allOk = data.reduce((prev, el)=>{ prev &&= this.checkIsCompleted(el['status']); return prev },true)
        if(allOk) {
          clearInterval(this.liveWire) //livewire no longer needed
          this.liveWire = null
        }
      }
    },

    onRowSelected(ev) {
      this.invoiceSelected = ev[0]
    },

    async uploadClick() {
      if(!this.file) return logWarning("no has seleccionado ningún archivo")
      await ApiService.uploadFile(this.file,`api/invoice/upload`)
      logInfo("factura subida")
      await this.fetchInvoices()
    },

    onFile() {
      this.file = _.get(this.$refs.file, 'files[0]')
    },

    selectRow(num) {
      this.$nextTick(() => this.$refs.invoiceTable.selectRow(num));
    },

    async fetchInvoices() {
      const {data} = await ApiService.get(`api/invoice`)
      this.invoices = data
      if (data && data.length) {
        this.invoiceSelected = data[0]
        this.currentPage = 1
        this.selectRow(0)
      }
      this.pending = this.invoices.filter(el=>this.checkIsProcessing(el['status']))
      if(this.pending.length) {
        //start pull interval to update state (live wire). To avoid using websockets
        if(!this.liveWire){
          this.liveWire = setInterval(async function () {
            await this.pullLiveWire();
          }.bind(this), 5000);
        }
      }
    },
  },

  computed: {

  },

  async mounted() {
    await this.fetchInvoices()
  }
};
</script>

<style>

.main-container {
  width: 1400px;
}
.right-container {
  width: 915px;
}

.left-container {
  width: 480px;

}


</style>
