<template>

  <div class="d-flex flex-column mr-1 ">

    <div v-if="model" class="my-container d-flex flex-column">


      <div class="d-flex">
        <label class="my-0 mr-1"><u><b>Página:</b></u></label>
        <span>{{model.page}}/{{pages}}</span>
      </div>
      <div class="d-flex">
        <label class="my-0 mr-1"><u><b>Receptor:</b></u></label>
        <span>{{xInvoiceCustomerName}}</span>
      </div>
      <div class="d-flex">
        <label class="my-0 mr-1"><u><b>CIF Receptor:</b></u></label>
        <span>{{xInvoiceCustomerCIF}}</span>
      </div>




    </div>

    <div class="my-window my-2">
      <div class="my-title-bar">
        <div class="my-title-bar-text">
          Campos
        </div>
      </div>
      <div class="my-window-body">
        <div class="d-flex flex-column">

          <div class="my-form d-flex flex-column m-0 mb-1">

            <div class="d-flex justify-content-between">
              <label>Id Contrato</label>
              <div class="progress-bar">
                <span class="progress-bar-fill"
                      :class="resolveProgressColorClass(xId.pct)"
                      :style="{'width': `${xId.pct}%`}"></span>
              </div>
            </div>

            <input v-model="xId.val">
          </div>

          <div class="my-form d-flex flex-column m-0 mb-1">
            <div class="d-flex justify-content-between">
              <label>Fecha</label>
              <div class="progress-bar">
                <span class="progress-bar-fill"
                      :class="resolveProgressColorClass(xDate.pct)"
                      :style="{'width': `${xDate.pct}%`}"></span>
              </div>
            </div>
            <input v-model="xDate.val">
          </div>



          <div class="my-form d-flex flex-column m-0 mb-1">
            <div class="d-flex justify-content-between">
              <label>Emisor</label>
              <div class="progress-bar">
                <span class="progress-bar-fill"
                      :class="resolveProgressColorClass(xName.pct)"
                      :style="{'width': `${xName.pct}%`}"></span>
              </div>
            </div>
            <input v-model="xName.val">
          </div>


          <div class="my-form d-flex flex-column m-0 mb-1">
            <div class="d-flex justify-content-between">
              <label>NIF Emisor</label>
              <div class="progress-bar">
                <span class="progress-bar-fill"
                      :class="resolveProgressColorClass(xTax.pct)"
                      :style="{'width': `${xTax.pct}%`}">
                </span>
              </div>
            </div>
            <input v-model="xTax.val">
          </div>


          <div class="my-form d-flex flex-column m-0 mb-1">
            <div class="d-flex justify-content-between">
              <label>Total Factura</label>
              <div class="progress-bar">
                <span class="progress-bar-fill"
                      :class="resolveProgressColorClass(xTotal.pct)"
                      :style="{'width': `${xTotal.pct}%`}">
                </span>
              </div>
            </div>
            <input v-model="xTotal.val">
          </div>

          <div class="d-flex">
            <div class="progress-bar mr-1" style="width: 20px">
              <span class="progress-bar-fill success-bar" style="width: 100%"></span>
            </div>
            <div class="progress-bar mr-1" style="width: 20px">
              <span class="progress-bar-fill warning-bar" style="width: 100%"></span>
            </div>
            <div class="progress-bar mr-1" style="width: 20px">
              <span class="progress-bar-fill danger-bar" style="width: 100%"></span>
            </div>

            <span>fiabilidad</span>

          </div>



        </div>

      </div>
    </div>

  </div>



</template>

<script>


import ApiService from "@/services/api.service";
import { toDateStr} from "@/helpers";
import _ from "lodash";

export default {
  name: "invoice-detail",
  props: ['model','pages','id'],

  data() {
    return {
    };
  },

  computed: {


    xFields() {
      return this.model.ocr.f_fields
    },
    xInvoiceCustomerName() {
      return _.get(this.model, 'ocr.fields.CustomerName.value')
    },
    xInvoiceCustomerCIF() {
      return _.get(this.model, 'ocr.fields.CustomerTaxId.value')
    },
    xId() {
      return this.extractPropValues('id')
    },
    xName() {
      return this.extractPropValues('vendor_name')
    },
    xDate() {
      return this.extractPropValues('date')
    },
    xTotal() {
      return this.extractPropValues('total')
    },
    xTax() {
      return this.extractPropValues('vendor_taxid')
    },


  },


  methods: {
    toDateStr,


    extractPropValues(prop) {
      return {
        val: _.get(this.xFields, `${prop}.value`),
        pct: _.get(this.xFields, `${prop}.confidence`,0) * 100
      }
    },
    resolveProgressColorClass(pct) {
      return pct>=90 ? 'success-bar' : pct>=60 ? 'warning-bar': 'danger-bar'
    }
  },


  async mounted() {}
};
</script>

<style>


.success-bar {
  background-color: #1a6700 !important;
}

.warning-bar {
  background-color: #ff9907 !important;
}

.danger-bar {
  background-color: red !important;
}


.progress-bar {
  margin-top: 3px;
  width: 100px;
  height: 11px;
  border: 1px solid black;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 3px;
}

.progress-bar-fill {
  display: block;
  height: 8px;
  background-color: #1a6700;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}



</style>
