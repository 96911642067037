<template>

  <div class="d-flex ocr-main-container">
    <div class="ocr-left-container" :style="{
      'border': `2px solid ${color}`,
    }">
      {{ellipsis(k,20,false)}}
    </div>

    <div class="ocr-right-container" :style="{
      'border-right': `2px solid ${color}`,
      'border-top': `2px solid ${color}`,
      'border-bottom': `2px solid ${color}`,
    }">
      {{ellipsis(v,30,false)}}
    </div>

  </div>


</template>

<script>


import ApiService from "@/services/api.service";
import {ellipsis, toDateStr} from "@/helpers";
import _ from "lodash";

export default {
  name: "ocr-field",
  props: ['color', 'k', 'v'],

  data() {
    return {
    };
  },

  computed: {

  },


  methods: {
    ellipsis,
  },


  async mounted() {}
};
</script>

<style scoped>

.ocr-main-container {
  font-family: monospace;
  font-size: 10px;
  /*width: 125px;*/
  margin: 1px;
}
.ocr-right-container {
  /*width: 80px;*/
  /*border-right: 1px solid red;*/
  /*border-top: 1px solid red;*/
  /*border-bottom: 1px solid red;*/

}

.ocr-left-container {
  text-decoration: underline;
  /*width: 45px;*/
  border: 1px solid red;

}
</style>
